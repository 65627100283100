import React from 'react';
import {
    ProjectDetailContainer,
    ProjectDetailTitle,
    ProjectDetailText,
    ProjectDetailImage,
    ProjectDetailVideo,
} from '../components/infoPage';
import { graphql, Link } from 'gatsby';
import { MarkdownText } from '../components/markdown';
import { Overlay, OverlayHeader, OverlayBody } from '../components/overlay';
import { desktop } from '../components/responsive';
import { IconClose } from '../components/icons';
import { typo, colors, rem } from '../components/styleguide';

export const query = graphql`
    query($slug: String!) {
        projectJson(fields: { slug: { eq: $slug } }) {
            fields {
                slug
            }
            date(formatString: "MM/YYYY")
            title
            description
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            video {
                stillImage {
                    childImageSharp {
                        resize(width: 1024) {
                            src
                            width
                            height
                            aspectRatio
                        }
                    }
                }
                media {
                    src
                    type
                }
            }
        }
    }
`;

const Content = ({ projectJson }) => (
    <>
        <ProjectDetailTitle project={projectJson} />
        <ProjectDetailText>
            <MarkdownText text={projectJson.description} />
        </ProjectDetailText>
        <ProjectDetailVideo video={projectJson.video} />
        <ProjectDetailImage image={projectJson.image} />
    </>
);

const Page = ({ data: { projectJson } }) => (
    <>
        <Overlay containerStyles={[desktop({ display: 'none' })]}>
            <OverlayHeader
                css={{
                    flexDirection: 'column',
                    overflow: 'hidden',
                    justifyContent: 'center',
                    height: '7rem',
                }}
            >
                <Link to="/info">
                    <IconClose />
                </Link>
                <div
                    css={[
                        typo.navigation,
                        {
                            color: colors.white,
                            marginTop: `1rem`,
                        },
                    ]}
                >
                    Projekte
                </div>
            </OverlayHeader>
            <OverlayBody
                css={{
                    flexDirection: 'column',
                    overflowY: 'auto',
                    paddingBottom: '2rem',
                }}
            >
                <Content projectJson={projectJson} />
            </OverlayBody>
        </Overlay>
        <ProjectDetailContainer>
            <Content projectJson={projectJson} />
            <Link
                to="/info"
                css={{ position: 'absolute', left: rem(15), bottom: rem(12) }}
            >
                <IconClose />
            </Link>
        </ProjectDetailContainer>
    </>
);

export default Page;
